import { IdleActions } from '@abbadox-monorepo/core-idle';
import { selectSessionTimeoutInSeconds } from '@abbadox-monorepo/kiosk-auth-data-access';
import { INTERVAL_TO_EMIT_IN_MILLISECONDS, STARTING_VALUE_TO_EMIT } from '@abbadox-monorepo/kiosk-core-constants';
import { PageState } from '@abbadox-monorepo/kiosk-workflows-data-access';
import { IdsButton, IdsProgressBar } from '@abbadox-monorepo/shared-ui';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, model, signal, ViewEncapsulation } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { filter, switchMap, tap, timer } from 'rxjs';
import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

export interface KioskSessionTimeoutDialogData extends KioskDialogData {
  maxTimeout: number;
}

@Component({
  selector: 'kiosk-patient-session-timeout-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsButton, IdsProgressBar],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClickFromIcon()">
      <p class="message-line">
        This session will automatically time out in
        @if (timer$ | ngrxPush) {}
        <span>{{ timerRemaining() }} seconds due to inactivity.</span>
      </p>
      <ids-progress-bar mode="determinate" [value]="progress()"></ids-progress-bar>
      <div class="dialog-actions-footer">
        <button ids-adc-button color="secondary" (click)="handleCloseClickFromButton()">Need more time</button>
      </div>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./patient-session-timeout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskPatientSessionTimeoutDialog {
  readonly dialogRef = inject(DialogRef);
  readonly data = inject<KioskSessionTimeoutDialogData>(DIALOG_DATA);
  readonly store = inject(Store<PageState>);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);
  readonly maxTimeout = model(this.data.maxTimeout);

  /** Watcher for timer updates. */
  readonly timerRemaining = signal(0);
  readonly progress = signal(0);

  readonly timer$ = this.store.select(selectSessionTimeoutInSeconds).pipe(
    switchMap((maxTimeout) =>
      timer(STARTING_VALUE_TO_EMIT, INTERVAL_TO_EMIT_IN_MILLISECONDS).pipe(
        tap((countdown) => {
          this.timerRemaining.update(() => maxTimeout - countdown);
          this.progress.update(() => Math.ceil((countdown / maxTimeout) * 100));
        }),
        filter(() => this.progress() === 100),
        tap(() => this.returnToHome()),
      ),
    ),
  );

  handleCloseClickFromIcon() {
    this.dialogRef.close();
    this.store.dispatch(IdleActions.triggerTimeoutDialogCloseFromIcon());
  }

  returnToHome() {
    this.dialogRef.close();
    this.store.dispatch(IdleActions.triggerTimeoutDialogCloseFromTimeoutEvent());
  }

  handleCloseClickFromButton() {
    this.dialogRef.close();
    this.store.dispatch(IdleActions.triggerTimeoutDialogCloseFromButton());
  }
}
