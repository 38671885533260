import { ApiHttpService } from '@abbadox-monorepo/core-http-client';
import {
  Appointment,
  AppointmentsBatchUpdateDTO,
  GenericBatchUpdateResponse,
} from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsHttpService {
  private readonly apiHttpService = inject(ApiHttpService);

  /**
   * Finds patient records matching search params.
   *
   * There can be multiple records for a patient.
   *
   * @param args - query params to search for a patient
   * @returns - a list of patient appointments
   */
  findAppointments(query: Record<string, any>): Observable<Appointment[]> {
    let params = new HttpParams();

    for (const key in query) {
      if (query[key]) {
        params = params.append(key, query[key]);
      }
    }

    return this.apiHttpService.get<Appointment[]>('/appointments', params);
  }

  /**
   * Updates the status patient of appointments for the day.
   *
   * @param body - data to update in appointments
   * @returns count of appointments updated
   */
  updateAppointmentsStatuses(body: AppointmentsBatchUpdateDTO): Observable<GenericBatchUpdateResponse> {
    return this.apiHttpService.put<GenericBatchUpdateResponse, AppointmentsBatchUpdateDTO>('/appointments', body);
  }
}
