import { Appointment } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

export const AppointmentsApiActions = createActionGroup({
  source: 'Appointments Api',
  events: {
    fetchAppointmentsAttempted: props<{ patientId: number }>(),
    fetchAppointmentsAttemptedFromRouteGuard: props<{ patientId: number }>(),
    fetchAppointmentsSuccess: props<{ appointments: Appointment[] }>(),
    fetchAppointmentsFailed: props<{ error: any }>(),
    submitAppointmentsCommentAttempted: emptyProps(),
    submitAppointmentsCommentSuccess: emptyProps(),
    submitAppointmentsCommentFailed: props<{ error: any }>(),
    updateAppointmentsStatusAttempted: emptyProps(),
    updateAppointmentsStatusSuccess: emptyProps(),
    updateAppointmentsStatusFailed: props<{ error: any }>(),
  },
});

export const AppointmentsPageActions = createActionGroup({
  source: 'Appointments Page',
  events: {
    initAppointmentsState: emptyProps(),
    navigateToAppointments: emptyProps(),
    updateAppointmentsCommentForm: props<{ comment: string }>(),
  },
});

export interface AppointmentsState extends EntityState<Appointment> {
  appointmentsLoaded: boolean;
  comment: string;
  error: any;
}

const selectAppointmentId = (a: Appointment): string => {
  return a.appointmentID;
};

const appointmentsAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: selectAppointmentId,
});

export const initialAppointmentsState: AppointmentsState = appointmentsAdapter.getInitialState({
  appointmentsLoaded: false,
  comment: '',
  error: null,
});

const coerceToNumbers = (data: Array<string | number>) => data?.map((x) => Number(x));

export const appointmentsFeature = createFeature({
  name: 'appointments',
  reducer: createReducer(
    initialAppointmentsState,
    on(AppointmentsApiActions.fetchAppointmentsSuccess, (state, { appointments }) =>
      appointmentsAdapter.setAll(appointments, {
        ...state,
        appointmentsLoaded: Boolean(appointments.length), // dynamically set the loaded state for the guard
      }),
    ),
    on(AppointmentsPageActions.updateAppointmentsCommentForm, (state, { comment }) => ({
      ...state,
      comment,
    })),
    on(AppointmentsPageActions.initAppointmentsState, () => initialAppointmentsState),
  ),
  extraSelectors: ({ selectAppointmentsState, selectIds }) => ({
    ...appointmentsAdapter.getSelectors(selectAppointmentsState),
    selectAppointmentIds: createSelector(selectIds, (ids) => coerceToNumbers(ids)),
  }),
});

export const {
  selectAppointmentsLoaded,
  selectAppointmentIds,
  selectAll: selectAppointments,
  selectEntities: selectAppointmentEntities,
  selectComment: selectAppointmentComment,
} = appointmentsFeature;
