import { AppointmentsPageActions } from '@abbadox-monorepo/kiosk-appointments-data-access';
import { PatientRecordsPageActions, selectPatientId } from '@abbadox-monorepo/kiosk-patient-data-access';
import { Location } from '@angular/common';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, mergeMap, tap, withLatestFrom } from 'rxjs';
import { selectCurrentStep, selectNextStep, selectSelectedWorkflow, WorkflowUIActions } from './workflows.state';

/** Set current workflow and page based on route transition. */
export const setWoflowAndCurrentStep$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(routerNavigatedAction),
      mergeMap(({ payload }) => {
        const parts = payload.routerState.url.split('/');
        const [_, workflow, stepName] = parts;
        // either set the current step to the last part in the route or the second
        const currentStep = parts.length > 4 ? parts[parts.length - 1] : stepName;
        return [
          WorkflowUIActions.setWorkflow({ currentWorkflow: workflow }),
          WorkflowUIActions.setCurrentStep({ currentStep }),
        ];
      }),
    ),
  { functional: true },
);

/** Navigates to the previous step. */
export const navigateToPreviousStep$ = createEffect(
  (actions$ = inject(Actions), location = inject(Location)) =>
    actions$.pipe(
      ofType(WorkflowUIActions.setPrevStep),
      tap(() => {
        location.back();
      }),
    ),
  { functional: true, dispatch: false },
);

/** Navigate to home function that resets all workflows states. */
export const navigateToHome$ = createEffect(
  (actions$ = inject(Actions), router = inject(Router)) =>
    actions$.pipe(
      ofType(
        WorkflowUIActions.navigateToHomeFromRestartFooterButtonClick,
        WorkflowUIActions.navigateFromSessionIdleTimeoutToHome,
        WorkflowUIActions.navigateFromPatientAuthToHome,
        WorkflowUIActions.navigateFromAppointentsNotFoundDialogToHome,
        WorkflowUIActions.navigateToHomFromConfirmationToTimeoutEvent,
        WorkflowUIActions.navigateToHomFromConfirmationToButtonClick,
      ),
      tap(() => {
        router.navigate(['/home']);
      }),
      mergeMap(() => [
        PatientRecordsPageActions.initPatientRecordsState(),
        AppointmentsPageActions.initAppointmentsState(),
      ]),
    ),
  { functional: true },
);

/** Navigate to confirmation page. */
export const navigateToConfirmationPage$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(WorkflowUIActions.navigateToConfirmationPage),
      withLatestFrom(
        store.select(selectSelectedWorkflow),
        store.select(selectCurrentStep),
        store.select(selectNextStep),
        store.select(selectPatientId),
      ),
      filter(([, _, __, nextStep, patientId]) => Boolean(patientId && nextStep)),
      tap(([, workflow, currentStep, nextStep, patientId]) => {
        router.navigate([workflow, currentStep, patientId, nextStep]);
      }),
    ),
  { functional: true, dispatch: false },
);
