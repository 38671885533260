import { ApiHttpService } from '@abbadox-monorepo/core-http-client';
import { PageTemplateConfig } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Loads relevant configurations for the app.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkflowsHttpClientService {
  private readonly apiHttpService = inject(ApiHttpService);

  getPages(): Observable<PageTemplateConfig> {
    return this.apiHttpService.get<PageTemplateConfig>('/pages');
  }
}
