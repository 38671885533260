import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { WorkflowsApiActions } from './workflows.state';
import { parseConfig } from './workflows.parser';
import { WorkflowsHttpClientService } from './services/workflows-http-client.service';

/** Loads pages. */
export const loadWorkflows$ = createEffect(
  (actions$ = inject(Actions), pagesHttpClientService = inject(WorkflowsHttpClientService)) =>
    actions$.pipe(
      ofType(WorkflowsApiActions.loadWorkflowsAttempted),
      switchMap(() =>
        pagesHttpClientService
          .getPages()
          .pipe(map((config) => WorkflowsApiActions.loadWorkflowsSuccess(parseConfig(config)))),
      ),
    ),
  { functional: true },
);
