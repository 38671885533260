import { PatientInfo } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

export const PatientRecordsApiActions = createActionGroup({
  source: 'Patient Records Api',
  events: {
    loadPatientRecordsAttempted: emptyProps(),
    loadPatientRecordsSuccess: props<{ patient: PatientInfo | null; duplicate: boolean }>(),
    loadPatientRecordsFailed: props<{ error: any }>(),
    incrementPatientAuthRetryAttempts: emptyProps(),
    resetPatientAuthRetryAttempts: emptyProps(),
    fetchPatientDetailsAttempted: props<{ patientId: number }>(),
    fetchPatientDetailsSuccess: props<{ patient: PatientInfo }>(),
    fetchPatientDetailsFailed: props<{ error: any }>(),
    submitPatientRecordCommentsAttempted: emptyProps(),
    submitPatientRecordCommentsSuccess: emptyProps(),
    submitPatientRecordCommentsFailed: props<{ error: any }>(),
  },
});

export const PatientRecordsPageActions = createActionGroup({
  source: 'Patient Records Page',
  events: {
    initPatientRecordsState: emptyProps(),
    navigateToNewPatientForm: emptyProps(),
    triggerPatientNotFoundDialogCloseFromIcon: emptyProps(),
    triggerPatientNotFoundDialogCloseFromButton: emptyProps(),
    triggerCheckinFailedDialogCloseFromIcon: emptyProps(),
    triggerCheckinFailedDialogCloseFromButton: emptyProps(),
    triggerCheckinFailedDialogCloseFromTimeoutEvent: emptyProps(),
  },
});

export interface PatientRecordsState {
  patient: PatientInfo | null;
  duplicate: boolean;
  patientLoaded: boolean;
  attempts: number;
}

export const initialPatientRecordsState: PatientRecordsState = {
  patient: null,
  duplicate: false,
  patientLoaded: false,
  attempts: 0, // only legitimate, successful attempts should be tracked. API failures don't count
};

export const patientRecordsFeature = createFeature({
  name: 'patientRecords',
  reducer: createReducer(
    initialPatientRecordsState,
    on(PatientRecordsApiActions.loadPatientRecordsSuccess, (state, { patient, duplicate }) => ({
      ...state,
      patient,
      duplicate,
      patientLoaded: true,
    })),
    on(PatientRecordsApiActions.fetchPatientDetailsSuccess, (state, { patient }) => ({
      ...state,
      patient,
      duplicate: false,
      patientLoaded: true,
    })),
    on(PatientRecordsApiActions.incrementPatientAuthRetryAttempts, (state) => ({
      ...state,
      attempts: state.attempts + 1,
    })),
    on(
      PatientRecordsApiActions.resetPatientAuthRetryAttempts,
      PatientRecordsPageActions.triggerPatientNotFoundDialogCloseFromIcon,
      PatientRecordsPageActions.triggerPatientNotFoundDialogCloseFromButton,
      PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromIcon,
      PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromButton,
      (state) => ({
        ...state,
        attempts: 0,
      }),
    ),
    on(PatientRecordsPageActions.initPatientRecordsState, () => initialPatientRecordsState),
  ),
  extraSelectors: ({ selectPatient }) => ({
    selectPatientId: createSelector(selectPatient, (patient) => patient?.autoCount ?? 0),
    selectPatientIdMRN: createSelector(selectPatient, (patient) => patient?.mrn ?? ''),
  }),
});

export const {
  name: PATIENT_RECORDS_STATE_FEATURE_KEY,
  reducer: patientsRecordsReducer,
  selectPatientRecordsState,
  selectPatientId,
  selectPatientIdMRN,
  selectDuplicate,
  selectPatient,
  selectPatientLoaded,
  selectAttempts,
} = patientRecordsFeature;
