import { IdsButton } from '@abbadox-monorepo/shared-ui/lib/components/button/button.component';
import { IdsIcon } from '@abbadox-monorepo/shared-ui/lib/components/icon/icon.component';
import { NgIf } from '@angular/common';
import { Component, input, output } from '@angular/core';

interface FooterViewModel {
  step: string | undefined;
  prevStep: string | undefined;
  nextStep: string | undefined;
}

const initialFooter: FooterViewModel = {
  step: '',
  prevStep: '',
  nextStep: '',
};

@Component({
  selector: 'kiosk-footer',
  standalone: true,
  imports: [NgIf, IdsButton, IdsIcon],
  template: `
    <footer
      class="fixed bottom-0 left-0 flex h-[84px] w-full max-w-full items-center justify-between bg-white p-3 px-4"
    >
      <button
        class="h-[3.75rem] !text-[36px]"
        ids-adc-button
        color="tertiary"
        (click)="handlePrevStepClicked(footer().prevStep)"
      >
        <i idsIcon class="text-text-tertiary !h-8 !w-8" icon="arrow-left"></i>
        Back
      </button>

      @if (footer().step) {
        <button
          class="h-[3.75rem] !text-[36px]"
          ids-adc-button
          ids-outline-button
          color="critical"
          (click)="handleRestartWorkflowClicked()"
        >
          Restart
        </button>
      }

      @if (hideNextButton()) {
        <button
          class="h-[3.75rem] !text-[36px]"
          ids-adc-button
          color="primary"
          (click)="handleNextStepClicked(footer().nextStep)"
        >
          <i idsIcon class="text-text-primary-dark !h-8 !w-8" icon="arrow-right" iconPositionEnd></i>
          Next
        </button>
      } @else {
        <!-- keeps the spacing of this button when it's not visible so spacing remains even with other buttons -->
        <div class="w-[155px]"></div>
      }
    </footer>
  `,
})
export class KioskFooter {
  footer = input(initialFooter);
  onPrevStepClicked = output<string | undefined>();
  onRestartWorkflowClicked = output();
  onNextStepClicked = output<string | undefined>();

  handlePrevStepClicked(route?: string) {
    this.onPrevStepClicked.emit(route);
  }

  handleRestartWorkflowClicked() {
    this.onRestartWorkflowClicked.emit();
  }

  handleNextStepClicked(route?: string) {
    this.onNextStepClicked.emit(route);
  }

  /** Hide the next button on Billing and Confirmation pages. */
  hideNextButton() {
    return !/(billing|confirmation)/.test(this.footer().step ?? '');
  }
}
