import { IdsIcon } from '@abbadox-monorepo/shared-ui';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kiosk-tier-one-card-footer-notification',
  standalone: true,
  imports: [IdsIcon],
  template: `
    <div class="card-footer-notification">
      <span>
        <i idsIcon icon="info-circle" color="var(--color-scheme-primary)"></i>
      </span>
      <p>
        Note: If any of the information above needs to be updated, please notify the front desk staff after you are
        checked in.
      </p>
    </div>
  `,
  styleUrls: ['./tier-one-card-footer-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KioskTierOneCardFooterNotification {}
