import { IdsIcon } from '@abbadox-monorepo/shared-ui';
import {
  IdsCardComponent,
  IdsCardContent,
  IdsCardFooter,
  IdsCardHeader,
  IdsCardTitle,
} from '@abbadox-monorepo/shared-ui/lib/components/card/card.component';
import { IconNames } from '@abbadox-monorepo/shared-ui/lib/components/icon';
import { ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';

export interface KioskDialogData {
  title: string;
  iconName?: IconNames;
}

@Component({
  selector: 'kiosk-dialog-shell',
  standalone: true,
  imports: [IdsCardComponent, IdsCardHeader, IdsCardFooter, IdsCardTitle, IdsCardContent, IdsIcon],
  template: `
    <div class="dialog-container">
      <div class="dialog-header dialog-title-outlined">
        <span class="header-title">
          <i idsIcon [icon]="icon()" color="var(--color-scheme-alert)"></i>
          <h2>{{ title() }}</h2>
        </span>
        <span class="header-close-action">
          <button (click)="closeDialog()">
            <i idsIcon icon="close" color="var(--color-scheme-text-secondary)"></i>
          </button>
        </span>
      </div>
      <ids-card tier-2 ids-card-outlined color="var(--color-scheme-alert)">
        <ids-card-content>
          <ng-content></ng-content>
        </ids-card-content>
      </ids-card>
    </div>
  `,
  styleUrls: ['./dialog-shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskDialogShell {
  readonly title = input('');
  readonly icon = input('exclamation-circle');

  readonly closeClick = output();

  closeDialog() {
    this.closeClick.emit();
  }
}
