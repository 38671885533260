import { PatientRecordsPageActions, PatientRecordsState } from '@abbadox-monorepo/kiosk-patient-data-access';
import { IdsButton, IdsProgressBar } from '@abbadox-monorepo/shared-ui';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject, model, ViewEncapsulation } from '@angular/core';
import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { KioskDialogData, KioskDialogShell } from '../dialog-shell/dialog-shell.component';

@Component({
  selector: 'kiosk-patient-not-found-dialog',
  standalone: true,
  imports: [PushPipe, KioskDialogShell, IdsProgressBar, IdsButton],
  template: `
    <kiosk-dialog-shell [title]="title()" (closeClick)="handleCloseClickFromIcon()">
      <p class="message-line">We did not find an existing patient record with the information you provided.</p>
      <p class="message-line">
        If you think you entered your Date of Birth or Last Name incorrectly, please try again or speak to the front
        desk.
      </p>
      <p class="message-line">Otherwise proceed as new patient.</p>
      <div class="dialog-actions-footer">
        <button ids-adc-button color="tertiary" (click)="handleTryAgainClickFromButton()">Try Again</button>
        <button ids-adc-button color="secondary" (click)="handleNewPatientClickFromButton()">I'm a New Patient</button>
      </div>
    </kiosk-dialog-shell>
  `,
  styleUrls: ['./patient-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskPatientNotFoundDialog {
  readonly dialogRef = inject(DialogRef);
  readonly data = inject<KioskDialogData>(DIALOG_DATA);
  readonly store = inject(Store<PatientRecordsState>);

  /** Model changes for the dialog's content. */
  readonly title = model(this.data.title);
  readonly icon = model(this.data.iconName);

  handleCloseClickFromIcon() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.triggerCheckinFailedDialogCloseFromIcon());
  }

  handleTryAgainClickFromButton() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.triggerPatientNotFoundDialogCloseFromButton());
  }

  handleNewPatientClickFromButton() {
    this.dialogRef.close();
    this.store.dispatch(PatientRecordsPageActions.navigateToNewPatientForm());
  }
}
