import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { KioskTierOneCardComponent } from '../cards/tier-one-card.component';
import { IdsButton } from '@abbadox-monorepo/shared-ui';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'kiosk-not-found-page',
  standalone: true,
  imports: [IdsButton, RouterLink, KioskTierOneCardComponent],
  template: `
    <kiosk-tier-one-card>
      <span tierOneTitle>404: Not Found</span>
      <p>Hey! It looks like this page doesn't exist yet.</p>
      <button class="h-[3.75rem] !text-[36px]" ids-adc-button ids-outline-button color="primary" routerLink="/">
        Take Me Home
      </button>
    </kiosk-tier-one-card>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotFoundPage {}
