import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { selectDuplicate } from './patient-records.state';

export const PatientSearchFormActions = createActionGroup({
  source: 'Patient Search Form',
  events: {
    updatePatientSearchForm: props<{ [key: string]: any }>(),
    submitPatientSearchForm: emptyProps(),
    resetAllPatientFormFields: emptyProps(),
    setFormFieldErrors: emptyProps(),
    resetFormFieldErrors: emptyProps(),
  },
});

export const PatientCommentsFormActions = createActionGroup({
  source: 'Patient Comments Form',
  events: {
    updatePatientRecordCommentsForm: props<{ comments: string }>(),
    resetPatientCommentsFormFields: emptyProps(),
  },
});

export interface PatientSearchForm {
  [key: string]: string;
}

export interface PatientSearchFormState {
  patientSearchForm: PatientSearchForm;
  searchFormErrors: boolean;
  comments: string;
}

const initialPatientSearchForm: PatientSearchForm = {
  dateOfBirth: '',
  lastName: '',
  phoneNumber: '',
};

/**
 * tracking the form state in global state is necessary for submitting the form from the global footer
 * otherwise, the footer will need to be in every page component
 */

export const initialPatientSearchFormState: PatientSearchFormState = {
  patientSearchForm: initialPatientSearchForm,
  searchFormErrors: false,
  comments: '',
};

export const patientSearchFormFeature = createFeature({
  name: 'patientSearchForm',
  reducer: createReducer(
    initialPatientSearchFormState,
    on(PatientSearchFormActions.updatePatientSearchForm, (state, { type, ...values }) => ({
      ...state,
      patientSearchForm: {
        ...state.patientSearchForm,
        ...values,
      },
    })),
    on(PatientSearchFormActions.setFormFieldErrors, (state) => ({
      ...state,
      searchFormErrors: true,
    })),
    on(PatientSearchFormActions.resetFormFieldErrors, (state) => ({
      ...state,
      searchFormErrors: false,
    })),
    on(
      PatientSearchFormActions.resetAllPatientFormFields,
      PatientCommentsFormActions.resetPatientCommentsFormFields,
      (state) => ({
        ...state,
        patientSearchForm: initialPatientSearchForm,
      }),
    ),
    on(PatientCommentsFormActions.updatePatientRecordCommentsForm, (state, { comments }) => ({
      ...state,
      comments,
    })),
  ),
  extraSelectors: ({ selectPatientSearchForm }) => ({
    selectPatientSearchFormFieldValues: createSelector(selectPatientSearchForm, (params) => params),
  }),
});

export const {
  name: PATIENT_SEARCH_FORM_STATE_FEATURE_KEY,
  reducer: patientSearchFormReducer,
  selectPatientSearchFormState,
  selectPatientSearchFormFieldValues,
  selectSearchFormErrors,
  selectComments: selectPatientComments,
} = patientSearchFormFeature;

export const selectDuplicateRecordsFormViewModel = createSelector(
  selectDuplicate,
  selectPatientSearchFormFieldValues,
  (duplicate, values) => ({ duplicate, values }),
);
