import { RadialProgressStepperComponent } from '@abbadox-monorepo/shared-ui/lib/components/radial-progress-stepper/radial-progress-stepper.component';
import { TitleCasePipe } from '@angular/common';
import { Component, input } from '@angular/core';

interface HeaderViewModel {
  logo?: string | null;
  stepTitle: string;
  stepName?: string;
  percentage: number;
}

const initialHeader: HeaderViewModel = {
  logo: '',
  stepTitle: '',
  stepName: '',
  percentage: 0,
};

@Component({
  selector: 'kiosk-header',
  standalone: true,
  imports: [RadialProgressStepperComponent, TitleCasePipe],
  template: `
    <header class="drop-shadow-light flex h-[84px] max-w-full items-center justify-between bg-white p-3 px-4">
      <div class="w-[270px]">
        @if (header().logo) {
          <img [src]="'data:image/png;base64, ' + header().logo" class="h-[3.25rem]" alt="Logo Here" />
        }
      </div>

      @if (header().percentage) {
        <ids-radial-progress-stepper [progress]="header().percentage"></ids-radial-progress-stepper>
      }

      <div class="flex min-w-[270px] flex-col items-end">
        <span class="text-[32px] font-medium leading-[38px] text-[#141414]">{{ header().stepTitle | titlecase }}</span>
        <span class="text-2xl leading-7 text-[#787885]">{{ header().stepName | titlecase }}</span>
      </div>
    </header>
  `,
})
export class KioskHeaderComponent {
  header = input(initialHeader);
}
