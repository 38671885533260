import {
  IdsCardComponent,
  IdsCardContent,
  IdsCardHeader,
  IdsCardTitle,
} from '@abbadox-monorepo/shared-ui/lib/components/card/card.component';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kiosk-tier-three-card',
  standalone: true,
  imports: [IdsCardComponent, IdsCardHeader, IdsCardTitle, IdsCardContent],
  template: `
    <ids-card tier-3 ids-card-outlined [color]="color()">
      @if (title()) {
        <ids-card-header>
          <ids-card-title>{{ title() }}</ids-card-title>
        </ids-card-header>
      }
      <ids-card-content><ng-content></ng-content></ids-card-content>
    </ids-card>
  `,
  styleUrls: ['./tier-three-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskTierThreeCardComponent {
  readonly title = input('');
  readonly color = input('');
}
