import {
  IdsCardComponent,
  IdsCardContent,
  IdsCardFooter,
  IdsCardHeader,
  IdsCardTitle,
  IdsCardSubtitle,
} from '@abbadox-monorepo/shared-ui/lib/components/card/card.component';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kiosk-tier-two-card',
  standalone: true,
  imports: [IdsCardComponent, IdsCardHeader, IdsCardTitle, IdsCardSubtitle, IdsCardContent, IdsCardFooter],
  template: `
    <ids-card tier-2 ids-card-outlined [color]="color()">
      <ids-card-header>
        <ids-card-title>
          <ng-content select="[tierTwoTitle]"></ng-content>
        </ids-card-title>
        <ids-card-subtitle>
          <ng-content select="[tierTwoSubTitle]"></ng-content>
        </ids-card-subtitle>
      </ids-card-header>
      <ids-card-content><ng-content></ng-content></ids-card-content>
      <ids-card-footer><ng-content select="[tierTwoFooter]"></ng-content></ids-card-footer>
    </ids-card>
  `,
  styleUrls: ['./tier-two-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskTierTwoCardComponent {
  readonly color = input('');
}
