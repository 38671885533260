import { ApiHttpService } from '@abbadox-monorepo/core-http-client';
import { PatientInfo, PatientRecordsResponse, PatientCommentsDTO } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientSearchForm } from '../patient-checkin-form.state';

@Injectable({
  providedIn: 'root',
})
export class PatientHttpService {
  private readonly apiHttpService = inject(ApiHttpService);

  /**
   * Finds a single patient record by patient id.
   *
   * @param patientId - the patient id/mrn to search for
   * @returns - the found patient record
   */
  findPatient(patientId: string): Observable<PatientInfo> {
    return this.apiHttpService.get<PatientInfo>(`/patients/${patientId}`);
  }

  /**
   * Finds patient records matching search params.
   *
   * There can be multiple records for a patient.
   *
   * @param args - query params to search for a patient
   * @returns - a list of patients matching a search query
   */
  findPatients(formState: PatientSearchForm): Observable<PatientRecordsResponse> {
    let params = new HttpParams();

    for (const key in formState) {
      if (formState[key]) {
        params = params.append(key, formState[key]);
      }
    }

    return this.apiHttpService.get<PatientRecordsResponse>('/patients', params);
  }

  /**
   * Updates the comments on a patient record.
   *
   * @param patientId - the id for the patient record to update
   * @param params - the data to update on the patient record
   * @returns - the modified patient record
   */
  updatePatientRecordsComments(patientId: string, params: PatientCommentsDTO): Observable<PatientInfo> {
    return this.apiHttpService.put<PatientInfo, PatientCommentsDTO>(`/patients/${patientId}/comments`, params);
  }
}
