import { IdsBadge } from '@abbadox-monorepo/shared-ui/lib/components/badge/badge.component';
import {
  IdsCardComponent,
  IdsCardContent,
  IdsCardHeader,
  IdsCardSubtitle,
  IdsCardTitle,
} from '@abbadox-monorepo/shared-ui/lib/components/card/card.component';
import { IconNames, IdsIcon } from '@abbadox-monorepo/shared-ui/lib/components/icon';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kiosk-tier-one-card',
  standalone: true,
  imports: [IdsCardComponent, IdsCardHeader, IdsCardTitle, IdsCardSubtitle, IdsCardContent, IdsBadge, IdsIcon],
  template: `
    <ids-card tier-1 ids-card-raised>
      <ids-card-header>
        @if (icon()) {
          <ids-badge [badgeColor]="color()">
            <i idsIcon [icon]="icon()" color="white"></i>
          </ids-badge>
        }
        <ids-card-title><ng-content select="[tierOneTitle]"></ng-content></ids-card-title>
        <ids-card-subtitle><ng-content select="[tierOneSubTitle]"></ng-content></ids-card-subtitle>
      </ids-card-header>
      <ids-card-content><ng-content></ng-content></ids-card-content>
    </ids-card>
  `,
  styleUrls: ['./tier-one-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class KioskTierOneCardComponent {
  readonly icon = input<IconNames | string>('');
  readonly color = input('');
}
