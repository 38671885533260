import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { PageState, WorkflowsApiActions } from '../workflows.state';

export const workflowsResolver: ResolveFn<boolean> = () => {
  const store = inject(Store<PageState>);

  store.dispatch(WorkflowsApiActions.loadWorkflowsAttempted());

  return of(true);
};
