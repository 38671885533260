import { KioskTierThreeCardComponent } from '@abbadox-monorepo/kiosk-ui';
import { Component, input, output, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PushPipe } from '@ngrx/component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';

@Component({
  selector: 'kiosk-comments-form',
  standalone: true,
  imports: [PushPipe, ReactiveFormsModule, KioskTierThreeCardComponent],
  template: `
    <kiosk-tier-three-card title="Any comments for front desk?" [color]="color() || 'var(--color-scheme-patient)'">
      <div class="comments-section">
        <textarea name="comments" id="appointment-comments" [formControl]="comment"></textarea>
        @if (commentValueChanges$ | ngrxPush) {}
      </div>
    </kiosk-tier-three-card>
  `,
  styleUrls: ['./comments-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KioskCommentsForm {
  color = input<string>();
  onCommentsUpdate = output<string>();

  readonly comment = new FormControl('');

  readonly commentValueChanges$ = this.comment.valueChanges.pipe(
    debounceTime(500),
    distinctUntilChanged(),
    tap((value) => {
      this.onCommentsUpdate.emit(value ?? '');
    }),
  );
}
