import { selectLogo } from '@abbadox-monorepo/kiosk-auth-data-access';
import { PageWorkflowsTemplateConfig, StartScreen } from '@abbadox-monorepo/kiosk-core-api-interfaces';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { createActionGroup, createFeature, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';

export const WorkflowsApiActions = createActionGroup({
  source: 'Workflows Api',
  events: {
    loadWorkflowsAttempted: emptyProps(),
    loadWorkflowsSuccess: props<{ startPage: StartScreen; workflows: PageWorkflowsTemplateConfig[] }>(),
    loadWorkflowsFailed: props<{ error: any }>(),
  },
});

export const WorkflowUIActions = createActionGroup({
  source: 'Workflow UI',
  events: {
    setWorkflow: props<{ currentWorkflow: string }>(),
    setCurrentStep: props<{ currentStep: string | undefined }>(),
    setNextStep: props<{ nextStep: string | undefined }>(),
    setPrevStep: props<{ prevStep: string | undefined }>(),
    navigateFromSessionIdleTimeoutToHome: emptyProps(),
    navigateFromPatientAuthToHome: emptyProps(),
    navigateFromAppointentsNotFoundDialogToHome: emptyProps(),
    navigateToHomFromConfirmationToTimeoutEvent: emptyProps(),
    navigateToHomFromConfirmationToButtonClick: emptyProps(),
    navigateToHomeFromRestartFooterButtonClick: emptyProps(),
    navigateToConfirmationPage: emptyProps(),
  },
});

type StartScreenState = StartScreen;

export interface PageState {
  startPage: StartScreenState;
  workflows: EntityState<PageWorkflowsTemplateConfig> & {
    selectedWorkflow: string;
    currentStep: string | undefined;
    nextStep: string | undefined;
    prevStep: string | undefined;
  };
  loaded: boolean;
  error: any;
}

const selectWorkflowName = (a: PageWorkflowsTemplateConfig): string => {
  // Although id can be the primary key, we want to set the page's route as the primary key and lookup id.
  return a.action.actionName;
};

const workflowsAdapter: EntityAdapter<PageWorkflowsTemplateConfig> = createEntityAdapter<PageWorkflowsTemplateConfig>({
  selectId: selectWorkflowName,
});

export const initialPageState: PageState = {
  startPage: {
    startScreenWidgetId: 0,
    heading: '',
    subHeading: '',
    icon: '',
    iconColor: '',
    isActive: false,
    startScreenWidgetSection: {
      isActive: false,
      heading: '',
      subHeading: '',
      startScreenWidgetSectionID: 0,
      accentColor: '',
    },
  },
  workflows: workflowsAdapter.getInitialState({
    selectedWorkflow: '',
    currentStep: '',
    nextStep: undefined,
    prevStep: undefined,
  }),
  loaded: false,
  error: null,
};

export const workflowsFeature = createFeature({
  name: 'workflows',
  reducer: createReducer(
    initialPageState,
    on(WorkflowsApiActions.loadWorkflowsSuccess, (state, { startPage, workflows }) => ({
      ...state,
      startPage,
      workflows: workflowsAdapter.setAll(workflows, {
        ...state.workflows,
      }),
      loaded: true,
    })),
    on(WorkflowsApiActions.loadWorkflowsFailed, (state) => ({
      ...state,
      loaded: false,
    })),
    on(WorkflowUIActions.setWorkflow, (state, { currentWorkflow }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        selectedWorkflow: currentWorkflow,
      },
    })),
    on(WorkflowUIActions.setCurrentStep, (state, { currentStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        currentStep,
      },
    })),
    on(WorkflowUIActions.setNextStep, (state, { nextStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        nextStep,
      },
    })),
    on(WorkflowUIActions.setPrevStep, (state, { prevStep }) => ({
      ...state,
      workflows: {
        ...state.workflows,
        prevStep,
      },
    })),
    on(WorkflowUIActions.navigateToHomeFromRestartFooterButtonClick, (state) => ({
      ...state,
      workflows: {
        ...state.workflows,
        selectedWorkflow: '',
        currentStep: '',
        nextStep: undefined,
        prevStep: undefined,
      },
    })),
  ),
  extraSelectors: ({ selectWorkflows }) => ({
    ...workflowsAdapter.getSelectors(selectWorkflows),
    selectSelectedWorkflow: createSelector(selectWorkflows, ({ selectedWorkflow }) => selectedWorkflow),
    selectCurrentStep: createSelector(selectWorkflows, ({ currentStep }) => currentStep),
    selectNextStep: createSelector(selectWorkflows, ({ nextStep }) => nextStep),
  }),
});

export const {
  name: WORKFLOWS_STATE_FEATURE_KEY,
  reducer: workflowsReducer,
  selectStartPage,
  selectSelectedWorkflow,
  selectCurrentStep,
  selectNextStep,
  selectAll: selectWorkflows,
  selectEntities: selectWorkflowsEntities,
  selectLoaded: selectPageConfigsLoaded,
} = workflowsFeature;

/** Selects a workflow. */
export const selectWorkflow = createSelector(
  selectSelectedWorkflow,
  selectWorkflowsEntities,
  (worflow, entities) =>
    entities[worflow] ?? {
      workflowId: 0,
      sortOrder: 0,
      isActive: false,
      stepId: 0,
      stepName: '',
      stepWidgets: [],
      action: {
        isActive: false,
        actionId: 0,
        actionName: '',
        buttonName: '',
        icon: '',
      },
      steps: [],
    },
);

/** Selects the steps in a workflow. */
export const selectSteps = createSelector(selectWorkflow, (workflow) => workflow.steps);

/** Selects the active page based on the step name in a route. */
export const selectPage = createSelector(selectSteps, selectCurrentStep, (steps, currentStep) =>
  steps.find((step) => step.stepName === currentStep),
);

export const selectTotalSteps = createSelector(selectSteps, (steps) => steps.length);

/** Selects the active step number. */
export const selectActiveStep = createSelector(selectPage, (page) => page?.sortOrder || 0);

/** Selects the percent complete in a workflow. */
export const selectPercentComplete = createSelector(
  selectActiveStep,
  selectTotalSteps,
  (activeStep, totalSteps): number => Math.ceil((activeStep / totalSteps) * 100),
);

/** Assembles the header for a page to dynamically update based on the currently active page. */
export const selectPageHeader = createSelector(
  selectLogo,
  selectWorkflow,
  selectPage,
  selectPercentComplete,
  (logo, workflow, page, percentage) => ({
    logo,
    stepTitle: workflow.action.actionName,
    stepName: page?.stepName,
    percentage: page?.stepName === 'confirmation' ? 0 : percentage,
  }),
);

/** Assembles the footer for a page to dynamically update based on the currently active page. */
export const selectPageFooter = createSelector(selectSteps, selectPage, (steps, page) => {
  const stepIndex = page ? page.sortOrder - 1 : 0;
  const next = steps[stepIndex + 1];
  const prev = steps[stepIndex - 1];

  if (page?.stepName === 'confirmation') {
    return {
      step: page?.stepName,
      nextStep: undefined,
      prevStep: undefined,
    };
  }

  return {
    step: page?.stepName,
    nextStep: next ? next.stepName : undefined,
    prevStep: prev ? prev.stepName : undefined,
  };
});

/** Detects whether a page should display a footer or not. */
export const selectHasFooterActions = createSelector(selectPageFooter, (footer) => footer.nextStep ?? footer.prevStep);

export const selectPageWidgets = createSelector(selectPage, (page) => page?.stepWidgets);

export const selectPageWidget = createSelector(selectPageWidgets, (content) =>
  content
    ? content[0]
    : {
        isActive: false,
        iconOverride: null,
        sortOrder: 0,
        stepWidgetId: 0,
        headingOverride: '',
        subHeadingOverride: null,
        accentColorOverride: null,
        failureMessageOverride: null,
        widget: {
          heading: '',
          subHeading: null,
          icon: null,
          isActive: false,
          widgetId: 0,
          widgetName: '',
          accentColor: '',
          failureMessage: '',
        },
      },
);

export const selectPageFailureMessage = createSelector(
  selectPageWidget,
  (widget) => widget.failureMessageOverride ?? '',
);
